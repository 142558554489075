import React from "react";
import Layout from "../components/layout";
import styled, { css } from "styled-components";
import { Container, Col, Row } from "../components/container";
import Heading from "../components/heading";
import { Button, ButtonGroup } from "../components/button";
import calendar from "../images/icons/Calendar.svg";
import invite from "../images/icons/Invite.svg";
import tablet from "../images/icons/Medication/Tablet.svg";
import feedback from "../images/icons/Feedback.svg";
import postcode from "../images/icons/Postcode.svg";
import notes from "../images/icons/Notes.svg";

const items = [
  {
    title: "Diet",
    description: "See recipes and tips to help you manage your diet",
    icon: calendar
  },
  {
    title: "Record sharing",
    description:
      "We can send your latest readings to your healthcare team for review",
    icon: invite
  },
  {
    title: "Medication",
    description: "Find out more about common diabetes treatments",
    icon: tablet
  },
  {
    title: "Support",
    description: "Speak to an expert for information and advice",
    icon: feedback
  },
  {
    title: "Travel",
    description: "Information and tools if you're planning to take a trip",
    icon: postcode
  },
  {
    title: "Courses",
    description: "Structured courses for you to work through in your own time",
    icon: notes
  }
];

const StyledHeading = styled(Heading)`
  padding-top: ${props => props.theme.spacing.verySmall};
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledParagraph = styled.p`
  padding-top: ${props => props.theme.spacing.regular};
  margin-top: 0;
  margin-bottom: 0;

  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing.veryLarge};
  margin-bottom: ${props => props.theme.spacing.huge};
`;

const StyleListItemDescription = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

const ListItemContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.large} 0;
  border-radius: ${props => props.theme.radius};

  img {
    margin-left: 15px;
    margin-top: 5px;
  }

  ${props =>
    props.active &&
    css`
      background-color: ${props.theme.color.focusHighlight};

      img {
        filter: grayscale(100%);
      }
    `}
`;

const StyledListRow = styled(Row)`
  margin-top: ${props => props.theme.spacing.large};
`;

const ListItem = ({ text, description, icon, active, ...props }) => {
  return (
    <React.Fragment>
      <ListItemContainer active={active} {...props}>
        <Col size={1 / 5}>
          <img src={icon} alt={text} />
        </Col>
        <Col size={7 / 10}>
          <strong>{text}</strong>
          <StyleListItemDescription>{description}</StyleListItemDescription>
        </Col>
      </ListItemContainer>
    </React.Fragment>
  );
};

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interest: {}
    };
  }

  selectInterest(val) {
    this.setState({
      interest: { ...this.state.interest, [val]: !this.state.interest[val] }
    });
  }

  render() {
    return (
      <Layout
        leftButtonLink="/about-you"
        leftButtonText="Back"
        rightButtonText="Help"
        rightButtonLink="/"
      >
        <Container>
          <Row>
            <Col>
              <StyledHeading elem="h1" size={Heading.sizes.veryLarge}>
                What are you interested in?
              </StyledHeading>
              <StyledParagraph>
                Select the information, advice and features you're interested in
                using
              </StyledParagraph>
            </Col>
          </Row>
          <StyledListRow>
            <Col>
              {items.map((item, k) => (
                <ListItem
                  key={k}
                  text={item.title}
                  description={item.description}
                  active={this.state.interest[k]}
                  icon={item.icon}
                  onClick={() => this.selectInterest(k)}
                />
              ))}
            </Col>
          </StyledListRow>
          <Row>
            <Col>
              <StyledButton to="/dashboard">See my dashboard</StyledButton>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;
